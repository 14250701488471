<template>
  <div class="list-info" id="watermark">
    <top-bar :title="'企业管理'" :left="true"></top-bar>
    <div class="list-search">
      <div class="searchValue">
        <input v-model="dataForm.searchValue" placeholder="请输入企业名称搜索" @keyup.enter="onSearch" type="search" value="搜索" class="search-content" />
        <img class="search-icon" :src="require('@/assets/img/search.png')" />
        <van-icon v-if="dataForm.searchValue" class="clear-btn" @click="onCancel" name="clear" color="#999" size="16" />
      </div>
    </div>
    <div class="add-box" @click="$router.push('/business-add')">
      <div class="add-img">
        <img class="add-icon" :src="require('@/assets/img/add.png')" alt="">
        <div class="text">新增、添加</div>
      </div>
    </div>
    <div class="filterBar">
      <van-row>
        <van-col span="6">
          <p @click="show.orgShow = !show.orgShow" :style="{ color: show.orgShow ? '#387FF5':'#666666' }">
            {{ belongOrgTitle }}
            <img :src="require(`@/assets/img/${show.orgShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="show.gridShow = !show.gridShow" :style="{ color: show.gridShow ? '#387FF5':'#666666' }">
            {{ belongGridTitle }}
            <img :src="require(`@/assets/img/${show.gridShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="communityLabelCascader = !communityLabelCascader" :style="{ color: communityLabelCascader ? '#387FF5' : '#666666' }">
            企业标签
            <img :src="require(`@/assets/img/${communityLabelCascader ? 'pull-down-select' : 'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="show.typeShow = !show.typeShow" :style="{ color: show.typeShow ? '#387FF5':'#666666' }">
            {{ placeTypeTitle }}
            <img :src="require(`@/assets/img/${show.typeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <res-label v-show="communityLabelCascader" :list="userCommunityLabelTree" @selectLabel="selectLabel"></res-label>
    <van-popup
      v-model="show.orgShow"
      position="bottom">
      <van-picker
          title="所属机构"
          show-toolbar
          :columns="list.orgList"
          value-key="label"
          @confirm="orgConfirm"
          @cancel="show.orgShow = false"
      />
    </van-popup>

<!--    <van-popup-->
<!--      v-model="show.gridShow"-->
<!--      position="bottom">-->
<!--      <van-picker-->
<!--        title="所属网格"-->
<!--        show-toolbar-->
<!--        :columns="list.gridList"-->
<!--        value-key="label"-->
<!--        @confirm="gridConfirm"-->
<!--        @cancel="show.gridShow = false"-->
<!--      />-->
<!--    </van-popup>-->
    <grid-select v-show="show.gridShow" :list="list.gridList" @selectLabel="selectGrid" ref="gridSelect"></grid-select>
    <van-popup
      v-model="show.typeShow"
      position="bottom">
      <van-picker
        title="经营类型"
        show-toolbar
        :columns="list.placeTypeList"
        value-key="label"
        @confirm="typeConfirm"
        @cancel="show.typeShow = false"
      />
    </van-popup>

    <div class="cont" v-show="!show.gridShow">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span>{{totalCount}}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id">
            <div class="title">
              <div class="title-left">
                {{item.contact + ' ' + item.contactMobile}}
              </div>
              <div class="title-right">
                <img src="@/assets/img/mobile.png" alt="" class="tel" @click.stop="getRealMobile(item.id)"/>
              </div>
            </div>
            <div class="content" @click="selectInfo(item.id)">
              <van-image :src="item.url||require('@/assets/img/default.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/default.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/default.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{item.name}}</div>
                <div class="bottom-text">{{item.address}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="bottom-icon"/>
            </div>
            <div class="content-bottom">
              <div class="bottom-left" style="right:0" v-if="item.gridId !== 0 && item.gridId !== null">{{item.gridName}}</div>
              <div class="bottom-left" style="right:0" v-else>{{item.subAreaName || item.communityName}}</div>
              <div class="bottom-right">
                <van-button plain size="small" @click="deleteClick(item.id)" style="background-color:transparent; color: #4581F8;border: 1px solid #4581F8;margin-right: 20px;">删除</van-button>
                <van-button size="small" v-if="isPatrolOpen" style="border: 1px solid #4581F8;margin-right: 20px;" @click="goPatrol(item)">巡查</van-button>
                <van-button size="small" v-if="isCensorOpen" @click="goCensor(item)">检查</van-button>
              </div>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import ResLabel from "../res-label.vue";
import {getbelongGrid, getDictTree, getbelongSubArea, listComRightLabel, getMiniGrid, getPlaceMobile} from "@/utils/common";
import {getImageStream} from "@/utils/index";
import {desensitization} from "@/utils/utils";
import GridSelect from '../../userRes/grid-select.vue'
import Vue from "vue";

export default {
  name: 'place',
  components: {
    topBar,
    ResLabel,
    GridSelect
  },
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.dataForm.orgId = this.$orgId;

    let that = this;
    getbelongSubArea(this.$orgId, function (e) {
      that.list.orgList = that.list.orgList.concat(e);
    });
    // getbelongGrid({orgId: this.$orgId}, function (e) {
    //   that.list.gridList = that.list.gridList.concat(e);
    // });
    getDictTree({code: "placeType"}, function (e) {
      that.list.placeTypeList = that.list.placeTypeList.concat(e);
    });
    getMiniGrid({community:this.$orgId}, function (list) {
      that.list.gridList = list
    })
    this.init();
  },
  data() {
    return {
      loading: false,
      pullLoading: false,
      finished: false,
      totalCount: 0,
      dataList: [],
      orgStr:'所属机构',
      gridStr:'所属网格',
      placeTypeStr:'场所类型',
      dataForm: {
        page: 0,
        limit: 5,
        gridId: "",
        miniGridId: "",
        placeType: "",
        houseType: "",
        searchValue: "",
        orgId: "30",
        labels: []
        // token:
        //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMDQwMTkzIiwiaWF0IjoxNjIyNzE0MjY3LCJleHAiOjE2MjMzMTkwNjd9.QeU4nOWTo6ARTR3ZQ_n_ONHif_m7GahavOjKUergTwrtjEysCpmoAObS1qUHlruJ0JVl4hACYVWENWEDif6Ekg",
      },
      list: {
        orgList: [{ label: "所有", value: "0" }],
        gridList: [],
        placeTypeList: [{ label: "所有", value: "0" }],
        placeHouseTypeList: [],
      },
      show: {
        orgShow: false,
        gridShow: false,
        typeShow: false,
      },
      belongOrgTitle: "所属机构",
      belongGridTitle: "所属网格",
      placeTypeTitle: "经营类型",
      houseTypeTitle: "房屋类型",
      userCommunityLabelTree: [],
      communityLabelCascader: false,
      isPatrolOpen: false,
      patrolAppInfo: {},
      isCensorOpen: false,
      censorAppInfo: {},
    };
  },
  methods: {
    selectLabel(e) {
      this.dataForm.labels = e;
      this.communityLabelCascader = false;
      this.onPullDownRefresh();
    },
    init() {
      listComRightLabel((success) => {
        this.userCommunityLabelTree = success;
      }, 5);
      this.dataForm.page = 0;
      this.dataList = [];
      this.getAppInfo()
      this.getDataList();
    },
    getAppInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/geMyAppInfoByCode'),
        method: 'GET',
        params: this.$http.adornParams({
          codes: 'sjgl_xcjl,sjgl_jc',
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          data.list.forEach(item => {
            if(item.appCode === 'sjgl_xcjl') {
              this.isPatrolOpen = true
              this.patrolAppInfo = item
            }else if (item.appCode === 'sjgl_jc'){
              this.isCensorOpen = true
              this.censorAppInfo = item
            }
          })
        }
      })
    },
    getRealMobile(id) {
      getPlaceMobile(id,3,function(data) {
        window.location.href = `tel://${data.mobile}`
      })
    },
    selectInfo(id) {
      this.$router.push({ path: "/business-info?id=" + id });
    },
    onSearch() {
      this.init();
    },
    onCancel() {
      this.dataForm.searchValue = "";
      this.init();
    },
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.dataForm.page = 0;
      this.getDataList()
    },
    goPatrol(item) {
      let url = this.patrolAppInfo.pageUrlH5.split('=')[0] + '=patrol-add'
      this.$router.push({
        path: '/appSSO',
        query: {
          placeId: item.id,
          placeType: 5,
          appId: this.patrolAppInfo.appId,
          appCode: this.patrolAppInfo.appCode,
          appName: this.patrolAppInfo.appName,
          url: url,
        }
      })
      // this.$router.push("/patrol-add");
    },
    goCensor(item) {
      let url = this.censorAppInfo.pageUrlH5.split('=')[0] + '=censor-add'
      this.$router.push({
        path: '/appSSO',
        query: {
          placeId: item.id,
          placeType: 7,
          appId: this.censorAppInfo.appId,
          appCode: this.censorAppInfo.appCode,
          appName: this.censorAppInfo.appName,
          url: url,
        }
      })
      // this.$router.push("/patrol-add");
    },
    // 所属机构
    orgConfirm(value, index) {
      if (value.value == "0") {
        this.dataForm.orgId = "";
        this.belongOrgTitle='所属机构'
      } else {
        this.dataForm.orgId = value.value;
        this.belongOrgTitle = value.label;
      }
      this.show.orgShow = false;
      this.init();
    },
    // // 所属网格
    // gridConfirm(value, index) {
    //   if (value.value == "0") {
    //     this.dataForm.gridId = "";
    //     this.belongGridTitle='所属网格'
    //   } else {
    //     this.dataForm.gridId = value.value;
    //     this.belongGridTitle = value.label;
    //   }
    //   this.show.gridShow = false;
    //   this.init();
    // },
    // 网格选择
    selectGrid (e) {
      this.dataForm.gridId = ''
      this.dataForm.miniGridId = ''
      const {selectList, labelCondition, depth} = e
      if (depth == 1) {
        this.dataForm.gridId = selectList
      } else {
        this.dataForm.miniGridId = selectList
      }
      this.gridStr = '所属网格'
      this.dataForm.page = 0;
      this.dataList = [];
      this.getDataList();
      this.show.gridShow = false
    },
    // 场所类型
    typeConfirm(value, index) {
      if (value.value == "0") {
        this.dataForm.placeType = "";
        this.placeTypeTitle = "经营类型";
      } else {
        this.dataForm.placeType = value.value;
        this.placeTypeTitle = value.label;
      }
      this.show.typeShow = false;
      this.init();
    },
    getDataList() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.dataForm.page++;
      this.$http({
        url: this.$http.adornUrl("/wxapp/place/placeinfoenterprise/list"),
        method: "post",
        params: this.$http.adornParams(this.dataForm),
      }).then(
        ({ data }) => {
          this.$toast.clear();
          if (data.code == 0) {
            this.totalCount = data.page.totalCount;
            if (this.dataList.length == data.page.totalCount) {
              this.$toast.clear();
              this.finished = true;
              return;
            }
            if (this.dataForm.page == 1 && data.page.list.length == 0) {
              this.dataList = [];
            }
            console.log("place---list");
            console.log(data);
            data.page.list.forEach((item) => {
              if (item.imgUrl && item.imgUrl.length != 0) {
                let urls = item.imgUrl.split(",");
                let url = getImageStream(urls[0]);
                item["url"] = url;
              }
              if (item.contactMobile) {
                item.contactMobile = desensitization(item.contactMobile)
              }
            });
            this.dataList = this.dataList.concat(data.page.list);
          }else {
            this.$toast.fail(data.msg)
          }
          // 加载状态结束
          this.loading = false
          this.pullLoading = false
        },
        (err) => {
          this.loading = false
          this.pullLoading = false
          this.$toast.clear()
        }
      );
    },
    deleteClick(id) {
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl("/wxapp/place/placeinfoenterprise/delete"),
          method: "post",
          data: this.$http.adornData(
              [id], false),
        }).then(({ data }) => {
          if (data.code == 0) {
            this.$toast.success({
              message: '删除成功',
              duration: 1500,
              onOpened: () => {
                setTimeout(() => {
                  this.onPullDownRefresh()
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg);
          }
        });
      })
    },
  },
};
</script>
